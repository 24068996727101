@font-face {
	font-family: 'PTRoot';
	src:
		url('../../fonts/PT-Root-UI_Regular.woff2') format('woff2'),
		url('../../fonts/PT-Root-UI_Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;

}

@font-face {
	font-family: 'PTRoot';
	src:
		url('../../fonts/PT-Root-UI_Bold.woff2') format('woff2'),
		url('../../fonts/PT-Root-UI_Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;

}


/* Set up Roboto for modern browsers, all weights */
// @supports (font-variation-settings: normal) {
//   @font-face {
//     font-family: 'PTRoot';
//     src: url('../../fonts/PT-Root-UI_VF.ttf') format('truetype');
//     font-weight: 300 800;
// 		font-display: swap;
//   }
// }